<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center" span="24">
      <!--      <router-link :to="{ name: 'StripePayment' }">-->
      <!--        <van-notice-bar v-if="status === 0" color="red"-->
      <!--                        left-icon="volume-o" >-->
      <!--          {{ $t("notice-bar.free") }}-->
      <!--        </van-notice-bar>-->
      <!--        <van-notice-bar v-else color="#1989fa"-->
      <!--                        left-icon="volume-o" >-->
      <!--          {{ $t("notice-bar.premium") }}-->
      <!--        </van-notice-bar>-->
      <!--      </router-link>-->
              <van-notice-bar
                  color="red"
                  left-icon="volume-o"
                  @click="openUrl"
              >
                  {{ $t("notice-bar.gas") }}
              </van-notice-bar>
      <div @click="runEasyPayAllService">
        <itserve-route
            :buttonName="$t('buttons.payNow')"
            type="wide"
            class="mt-15"
        ></itserve-route>
      </div>
    </van-col>
    <van-col class="itserve-center mt-15" span="24">
      <div class="main-page-account place-content-center">
        <div @click="putIndications">
          <itserve-route
              :buttonName="$t('buttons.putIndications')"
              class="nav-btn mt-15-simple"
              :icon="true"
              icon-color="#3C2C93"
              btn-style="box"
          >
            <send-indications-icon />
          </itserve-route>
        </div>
        <!--        <div @click="navigateToExternalLink">-->
        <!--          <itserve-route-->
        <!--              buttonName="Wiki AI"-->
        <!--              :params="$route.params"-->
        <!--              type="wide"-->
        <!--              class="nav-btn mt-15-simple fr"-->
        <!--              :icon="true"-->
        <!--              btn-style="box"-->
        <!--          >-->
        <!--            <img src="@/assets/images/wiki_logo.png" style="width: 52px; border-radius: 50%"/>-->
        <!--          </itserve-route>-->
        <!--        </div>-->
        <div @click="runEasyPayAllService">
          <itserve-route
              :params="$route.params"
              :buttonName="$t('buttons.pay')"
              type="wide"
              class="nav-btn mt-15-simple fr"
              :icon="true"
              icon-color="#1AAD62"
              btn-style="box"
          >
            <pay-icon />
          </itserve-route>
        </div>
        <itserve-route
            route-name="SelectMeterage"
            :buttonName="$t('buttons.energyCalculator')"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#D08CFA"
            btn-style="box"
        >
          <calculator-icon size="85%" />
        </itserve-route>
        <div @click="putShutdowns">
          <itserve-route
              :buttonName="$t('buttons.shutdowns')"
              type="wide"
              class="nav-btn mt-15-simple fr"
              :icon="true"
              icon-color="#FF8573"
              btn-style="box"
          >
            <shutdown-icon />
          </itserve-route>
        </div>
        <div @click="socialShare">
          <itserve-route
              :buttonName="$t('buttons.socialShare')"
              :params="$route.params"
              type="wide"
              class="nav-btn mt-15-simple"
              :icon="true"
              icon-color="#0fb1e5"
              btn-style="box"
          >
            <share-icon size="85%" />
          </itserve-route>
        </div>

        <!--        <itserve-route-->
        <!--            route-name="StripePayment"-->
        <!--            :buttonName="$t('settings.stripeButtonNew')"-->
        <!--            :params="$route.params"-->
        <!--            type="wide"-->
        <!--            class="nav-btn mt-15-simple"-->
        <!--            :icon="true"-->
        <!--            icon-color="lightgreen"-->
        <!--            btn-style="box"-->
        <!--        >-->
        <!--          <dollar-sign-icon />-->
        <!--        </itserve-route>-->

        <itserve-route
            route-name="WriteTicket"
            :buttonName="$t('buttons.contactUs')"
            :params="$route.params"
            type="wide"
            class="nav-btn mt-15-simple fr"
            :icon="true"
            icon-color="#FCC204"
            btn-style="box"
        >
          <contactus-icon />
        </itserve-route>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import PayIcon from "@/components/icons/pay-icon-box";
import ShutdownIcon from "@/components/icons/shutdown-icon-box";
import ContactusIcon from "@/components/icons/contactus-icon-box";
import SendIndicationsIcon from "@/components/icons/send-indications-icon-box";
//import ElectricIcon from "@/components/icons/electric-icon-box";
import CalculatorIcon from "@/components/icons/calculator-icon";
import ShareIcon from "vue-material-design-icons/Share";
import VanNoticeBar from "vant/lib/notice-bar/index";
// import DollarSignIcon from "@/components/icons/dollar-sign-icon";
export default {
  name: "Home",
  components: {
    VanNoticeBar,
    SendIndicationsIcon,
    CalculatorIcon,
    ShareIcon,
    ContactusIcon,
    ShutdownIcon,
    PayIcon,
    ItserveRoute,
    // DollarSignIcon,
  },
  data() {
    return {
     url: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
    status() {
      console.log("USER STATUS: " + this.$store.state.Auth.status);
      return this.$store.state.Auth.status;
    },
    shareStatus() {
      console.log("USER SHARE STATUS: " + this.$store.state.Auth.share_status);
      return this.$store.state.Auth.share_status;
    },
    status_sub() {
      return this.$store.state.Auth.status_sub;
    },
  },
  created() {
    this.getPageData();
    this.preloadInterstitial();
  },
  methods: {
    openUrl() {
      this.nativeSendEvent("url", "https://billing.city/volyn.gas");
    },
    preloadInterstitial() {
      if (this.shareStatus === 1 && !this.$store.state.Auth.isPreloaded) {
        console.log("preloadInterstitial CALL");
        this.nativeSendEvent("preloadInterstitial");
        this.$store.state.Auth.isPreloaded = true;
      }
    },
    async runEasyPayAllService() {
      if (this.shareStatus === 1) {
        console.log("CHLENIX!!!")
        await this.$router.push({name: "EasyPayAllServicePage"});
      } else {
        await this.$router.push({
          name: "ShareApp",
          params: this.$route.params,
        });
      }
    },
    async getPageData() {
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Auth/getSubscriptionStatus");
    },
    navigateToExternalLink() {
      window.location.href = "https://onelink.to/jyeydu";
    },
    async runPayment() {

      if(this.status === 1) {
        let url = "https://easypay.ua/ua/catalog/utility/harkov/erc-communal-term";
        this.nativeSendEvent("url", url);
      }
      else{
        await this.$router.push({
          name: "PayAllNew",
          params: this.$route.params,
        });
      }
    },
    async putIndications() {
      if (this.shareStatus === 1) {
        this.nativeSendEvent("url", process.env.VUE_APP_INDICATORS);
      } else {
        await this.$router.push({
          name: "ShareAppBeforePutIndication",
          params: this.$route.params,
        });
      }
    },
    putShutdowns() {
      this.nativeSendEvent("url", process.env.VUE_APP_SHUTDOWN)
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text: this.$t("settings.title") + " " + "https://billing.city/volyn.energo",
        subject: this.$t("settings.subject"),
      });
    }

  },
};
</script>
<style scoped>
@import "../../node_modules/vant/lib/notice-bar/index.css";
@import "../../node_modules/vant/lib/list/index.css";
.home-accounts-list {
  margin-bottom: 15px;
}
.home-page {
  background-color: #fff;
  padding-bottom: 15px;
  flex-grow: 1;
}
.main-page-account {
  padding-bottom: 15px;
}
</style>
<style>
.home-accounts-list .home-account {
  border-radius: 10px;
  max-width: 357px;
  -webkit-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
}
</style>
